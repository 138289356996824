import React from "react";
import { Activity, Sciences } from "../components";

const SciensesAndActivity = () => {
  return (
    <>
      <Sciences />
      <Activity />
    </>
  );
};

export default SciensesAndActivity;
